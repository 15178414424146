<template>
  <div>
    <v-container class="px-5">
      <v-card class="mx-auto" max-width="1200" outlined color="transparent">
        <v-card-text>
          <div
            class="mt-10 mb-5 text--primary text-center text-lg-h4 text-md-h5 text-sm-h6 font-weight-bold"
          >
            <span>
              2022년 <br />
              차별과 혐오에 희생된 벗들을<br />
              추모하는 기도회</span
            >
          </div>
          <v-divider></v-divider>
          <v-row justify="center" align="center" class="my-10">
            <v-col cols="6" justify="center" align="right">
              <v-img
                src="../assets/images/memorial/memorial.jpg"
                contain
                max-width="250"
            /></v-col>
            <v-col cols="6" justify="center" align="left">
              <v-img
                src="../assets/images/memorial/memorial_eng.jpg"
                contain
                max-width="250"
            /></v-col>
          </v-row>
          <br />
          <div
            class="pb-10 text--primar d-flex justify-center font-weight-bold"
          >
            <div class="text-body-1 text-sm-h5">
              KRU가 육우당 추모기도회에 시조번역과 특송으로 추모에
              참여하였습니다.
            </div>
          </div>

          <v-row>
            <v-col class="d-flex justify-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/fWlkt7El4x4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </v-col>
          </v-row>

          <!-- <div class="text--primary">
            <p>
              육우당 추모기도회는 2003년 4월 26일 18세의 나이로 세상을 떠난 고
              육우당 님과 혐오와 차별로 인해 희생된 성소수자들을 기리며
              2013년부터 매년 이어온 행사입니다.
            </p>
            <p>
              올해도 우리가 마음으로 기억해야 할 이름과 영혼이 그 수를
              더했습니다. 고 육우당님을 추모하고 아직 오지 않은 평등한 세상을
              위해 기도하는 올해 18주기 추모기도회에 여러분을 초청합니다.
            </p>
            <p>
              고 육우당과 우리 곁을 떠나간 성소수자 이웃들을 생각하며 기도회
              전까지 추모의 마음을 모을 수 있는 공간을 마련했습니다.

              <a href="http://bit.ly/2021memorialservice">
                http://bit.ly/2021memorialservice</a
              >
              위 링크에 방문하셔서 추모의 마음을 나누어 주세요.
            </p>
            <p class="mt-10">
              올해도 코로나19로 인해 온라인(유튜브)을 통해 모입니다.
            </p>
            <ul>
              <li>날짜 : 2021년 4월 22일(목) 오후 8시</li>
              <li>
                주소 : 무지개예수 유튜브채널(<a
                  href="https://www.youtube.com/channel/UCEUEV_1RkLBqwLtpsJlqvCA"
                  target="_blank"
                  >https://www.youtube.com/channel/UCEUEV_1RkLBqwLtpsJlqvCA</a
                >)
              </li>
              <li>
                예배 중 성찬이 있습니다. 각자 가능한 음료와 음식을 준비해주세요
              </li>
            </ul>
            <v-divider class="my-5 " style="width: 40rem;" />
            <ul>
              <li>
                주최 : 무지개예수
              </li>
              <li>
                후원계좌 : 기업은행 46706053101019 무지개예수(ㄱㅎㅈ)
              </li>
              <li>
                홈페이지 :
                <a href="www.rainbowyesu.org">www.rainbowyesu.org</a>
              </li>
              <li>
                연락처 : rainbowyesu@gmail.com
              </li>
            </ul>
          </div> -->
        </v-card-text>
      </v-card>
    </v-container>
    <v-container class="px-5 my-12">
      <v-card class="mx-auto" max-width="1200" outlined color="transparent">
        <v-divider></v-divider>
        <v-card-text>
          <div
            class="mt-10 mb-5 text--primary text-center text-lg-h4 text-md-h5 text-sm-h6 font-weight-bold"
          >
            <span> 2021년</span>
          </div>
          <div class="d-flex justify-center">
            <v-img
              src="../assets/images/memorial/legacy/memorial.jpg"
              width="50vw"
              height="50vh"
              contain
            />
          </div>
          <br />
          <div
            class="pb-10 text--primar d-flex justify-center font-weight-bold"
          >
            <div class="text-body-1 text-sm-h5">
              KRU가 육우당 추모기도회에 기도와 특송으로 추모에 참여하였습니다.
            </div>
          </div>

          <v-row>
            <v-col class="d-flex justify-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/48rinNWfFqk"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </v-col>
            <v-col class="d-flex justify-center"
              ><iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/m-ixakuMRMI"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe
            ></v-col>
          </v-row>

          <!-- <div class="text--primary">
            <p>
              육우당 추모기도회는 2003년 4월 26일 18세의 나이로 세상을 떠난 고
              육우당 님과 혐오와 차별로 인해 희생된 성소수자들을 기리며
              2013년부터 매년 이어온 행사입니다.
            </p>
            <p>
              올해도 우리가 마음으로 기억해야 할 이름과 영혼이 그 수를
              더했습니다. 고 육우당님을 추모하고 아직 오지 않은 평등한 세상을
              위해 기도하는 올해 18주기 추모기도회에 여러분을 초청합니다.
            </p>
            <p>
              고 육우당과 우리 곁을 떠나간 성소수자 이웃들을 생각하며 기도회
              전까지 추모의 마음을 모을 수 있는 공간을 마련했습니다.

              <a href="http://bit.ly/2021memorialservice">
                http://bit.ly/2021memorialservice</a
              >
              위 링크에 방문하셔서 추모의 마음을 나누어 주세요.
            </p>
            <p class="mt-10">
              올해도 코로나19로 인해 온라인(유튜브)을 통해 모입니다.
            </p>
            <ul>
              <li>날짜 : 2021년 4월 22일(목) 오후 8시</li>
              <li>
                주소 : 무지개예수 유튜브채널(<a
                  href="https://www.youtube.com/channel/UCEUEV_1RkLBqwLtpsJlqvCA"
                  target="_blank"
                  >https://www.youtube.com/channel/UCEUEV_1RkLBqwLtpsJlqvCA</a
                >)
              </li>
              <li>
                예배 중 성찬이 있습니다. 각자 가능한 음료와 음식을 준비해주세요
              </li>
            </ul>
            <v-divider class="my-5 " style="width: 40rem;" />
            <ul>
              <li>
                주최 : 무지개예수
              </li>
              <li>
                후원계좌 : 기업은행 46706053101019 무지개예수(ㄱㅎㅈ)
              </li>
              <li>
                홈페이지 :
                <a href="www.rainbowyesu.org">www.rainbowyesu.org</a>
              </li>
              <li>
                연락처 : rainbowyesu@gmail.com
              </li>
            </ul>
          </div> -->
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'MemorialPrayerMetting',
};
</script>

<style lang="scss" scoped>
.circle-image {
  border-radius: 100%;
}
ul {
  list-style: none;
}
ul li::before {
  content: '\2022';
  color: #4caf50;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
* {
  line-height: 2;
}
</style>
